@tailwind components;
@tailwind base;
@tailwind utilities;

:root {
  --color-red-100: #fed3d5;
  --color-red-200: #ff4954;
  --color-red-300: #4d0f12;
  --color-green-100: #f4f9e9;
  --color-green-200: #dfecbc;
  --color-green-300: #bfda79;
  --color-green-400: #95c11f;
  --color-green-500: #597413;
  --color-green-dark-100: #b8d996;
  --color-green-dark-200: #9bba79;
  --color-green-dark-300: #6e8d4e;
  --color-green-dark-400: #335014;
  --color-green-dark-500: #1f300c;
  --color-pink-100: #fdeff6;
  --color-pink-200: #facfe5;
  --color-pink-300: #f59eca;
  --color-pink-400: #ee5ea7;
  --color-pink-500: #8f3864;
  --color-pink-dark-400: #d30d70;
  --color-purple-100: #e9d5ff;
  --color-purple-200: #d8b4fe;
  --color-purple-300: #c084fc;
  --color-purple-400: #a249f5;
  --color-purple-500: #6b21a8;
  --color-yellow-100: #fffde5;
  --color-yellow-200: #fffab2;
  --color-yellow-300: #fff566;
  --color-yellow-400: #ffee00;
  --color-yellow-500: #998f00;
  --color-orange-100: #fef5ea;
  --color-orange-200: #fde2c0;
  --color-orange-300: #fbc580;
  --color-orange-400: #f89e2c;
  --color-orange-500: #955f1a;
  --color-blue-100: #e8fafc;
  --color-blue-200: #a5ebf1;
  --color-blue-300: #77e1eb;
  --color-blue-400: #1dcddd;
  --color-blue-500: #117b85;
  --color-blue-dark-100: #bfdbfe;
  --color-blue-dark-200: #93c5fd;
  --color-blue-dark-300: #60a5fa;
  --color-blue-dark-400: #3b82f6;
  --color-blue-dark-500: #1847ca;
  --color-blue-dark-600: #122d64;
  --color-blue-gray-200: #90a0d0;
  --color-white: #ffffff;
  --color-white-transparent-850: rgba(255, 255, 255, 0.85);
  --color-gray-100: #f5f5f5;
  --color-gray-200: #e0e0e0;
  --color-gray-300: #c2c2c2;
  --color-gray-400: #999999;
  --color-gray-500: #5e5e5e;
  --color-gray-600: #1d1d1b;
  --color-gray-700: #111110;
  --color-status-pendingPaiment: #c3dff9;
  --color-status-paymentConfirmed: #96ffc7;
  --color-status-kitSent: #cab8ff;
  --color-status-orderCompleted: #ffe5b2;
  --color-status-samplesCollecting: #f2fdad;
  --color-status-samplesCollectionCompleted: #fea334;
  --color-status-samplesSent: #ffff5e;
  --color-status-samplesInAnalysis: #c0fbff;
  --color-status-samplesCollectingPending: #eacef7;
  --color-status-orderCancelled: #ff9692;

  --color-ap-primary-light-green: #a2df93;
  --color-ap-primary-green: #2c6734;
  --color-ap-primary-dark-green: #395e43;
  --color-ap-primary-white: #ffffff;
  --color-ap-primary-black: #161616;
  --color-ap-main-green: #95c11f;
  --color-ap-main-dark-green: #627f14;
  --color-ap-lime-green-900: #53b43c;
  --color-ap-lime-green-800: #5fc247;
  --color-ap-lime-green-700: #6fc85b;
  --color-ap-lime-green-600: #80cf6e;
  --color-ap-lime-green-500: #91d581;
  --color-ap-lime-green-400: #a5dd98;
  --color-ap-lime-green-300: #b6e3ab;
  --color-ap-lime-green-200: #c6e9be;
  --color-ap-lime-green-100: #d7f0d1;
  --color-ap-olive-green-900: #243419;
  --color-ap-olive-green-800: #3c562a;
  --color-ap-olive-green-700: #54783a;
  --color-ap-olive-green-600: #6c9b4b;
  --color-ap-olive-green-500: #85b464;
  --color-ap-olive-green-400: #a0c587;
  --color-ap-olive-green-300: #bbd5a9;
  --color-ap-olive-green-200: #d6e6cb;
  --color-ap-olive-green-100: #f1f7ee;
  --color-ap-light-gray-900: #888886;
  --color-ap-light-gray-800: #a1a1a0;
  --color-ap-light-gray-700: #aeaead;
  --color-ap-light-gray-600: #bbbbba;
  --color-ap-light-gray-500: #c7c7c6;
  --color-ap-light-gray-400: #d4d4d3;
  --color-ap-light-gray-300: #e1e1e0;
  --color-ap-light-gray-200: #ededed;
  --color-ap-light-gray-100: #fafafa;
  --color-ap-dark-gray-900: #151514;
  --color-ap-dark-gray-800: #1f1f1e;
  --color-ap-dark-gray-700: #2c2c2b;
  --color-ap-dark-gray-600: #3c3c3b;
  --color-ap-dark-gray-500: #454544;
  --color-ap-dark-gray-400: #555553;
  --color-ap-dark-gray-300: #626260;
  --color-ap-dark-gray-200: #6f6f6d;
  --color-ap-dark-gray-100: #7b7b79;
  --color-ap-neutral-sage-900: #658667;
  --color-ap-neutral-sage-800: #709473;
  --color-ap-neutral-sage-700: #7f9f81;
  --color-ap-neutral-sage-600: #8daa90;
  --color-ap-neutral-sage-500: #9bb49d;
  --color-ap-neutral-sage-400: #aac0ac;
  --color-ap-neutral-sage-300: #b9cbba;
  --color-ap-neutral-sage-200: #d6e0d7;
  --color-ap-neutral-sage-100: #f3f6f4;
  --color-ap-neutral-mint-900: #cee9c8;
  --color-ap-neutral-mint-800: #ccedc4;
  --color-ap-neutral-mint-700: #d0f3c9;
  --color-ap-neutral-mint-600: #daf8d3;
  --color-ap-neutral-mint-500: #dfffd8;
  --color-ap-neutral-mint-400: #e1fcdb;
  --color-ap-neutral-mint-300: #e2fadd;
  --color-ap-neutral-mint-200: #e3f8df;
  --color-ap-neutral-mint-100: #e5f5e2;
  --color-ap-neutral-moss-900: #82a08f;
  --color-ap-neutral-moss-800: #91ab9c;
  --color-ap-neutral-moss-700: #9fb6a9;
  --color-ap-neutral-moss-600: #aec1b6;
  --color-ap-neutral-moss-500: #bdcdc4;
  --color-ap-neutral-moss-400: #cbd7d0;
  --color-ap-neutral-moss-300: #d6e0db;
  --color-ap-neutral-moss-200: #e8edea;
  --color-ap-neutral-moss-100: #f3f6f5;
  --color-ap-functional-red-900: #641d16;
  --color-ap-functional-red-800: #962c21;
  --color-ap-functional-red-700: #b83529;
  --color-ap-functional-red-600: #c4392c;
  --color-ap-functional-red-500: #d85c50;
  --color-ap-functional-red-400: #de7369;
  --color-ap-functional-red-300: #e79a93;
  --color-ap-functional-red-200: #f0c1bc;
  --color-ap-functional-red-100: #f9e8e6;
  --color-ap-functional-yellow-900: #eec611;
  --color-ap-functional-yellow-800: #f0cd2d;
  --color-ap-functional-yellow-700: #f2d44a;
  --color-ap-functional-yellow-600: #f4d962;
  --color-ap-functional-yellow-500: #f6e07d;
  --color-ap-functional-yellow-400: #f8e696;
  --color-ap-functional-yellow-300: #f9ecae;
  --color-ap-functional-yellow-200: #fbf1c6;
  --color-ap-functional-yellow-100: #fdf9e7;
  --color-ap-additional-mint-green-normal: #96ffc7;
  --color-ap-additional-mint-green-fade: #8aebb7;
  --color-ap-additional-lavender-normal: #cab8ff;
  --color-ap-additional-lavender-fade: #baa9eb;
  --color-ap-additional-peach-normal: #ffe5b2;
  --color-ap-additional-peach-fade: #ebd3a4;
  --color-ap-additional-lemonade-normal: #f2fdad;
  --color-ap-additional-lemonade-fade: #dfe99f;
  --color-ap-additional-orange-normal: #fea334;
  --color-ap-additional-orange-fade: #fea334;
  --color-ap-additional-canary-yellow-normal: #ffff5e;
  --color-ap-additional-canary-yellow-fade: #ebeb56;
  --color-ap-additional-sky-blue-normal: #c0fbff;
  --color-ap-additional-sky-blue-fade: #b1e7eb;
  --color-ap-additional-coral-normal: #ff9692;
  --color-ap-additional-coral-fade: #eb8a86;
  --color-ap-additional-water-normal: #c3e0fa;
  --color-ap-additional-water-fade: #bbcddd;
  --color-ap-additional-pink-normal: #eacef7;
  --color-ap-additional-pink-fade: #dcc5e7;
  --color-ap-black: #000000;
  --text-default: var(--color-gray-600);
  --color-error: var(--color-red-200);
  --layout-width: 1440px;
  --layout-padding: 20px;
  --color-garden-green: #09be1b;
  --color-garden-blue: #114fee;
  --color-garden-red: #ee1111;
}

html,
body {
  min-height: 100vh;
  font-family: "Inter", sans-serif;
}

html,
body,
#root {
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
}

body {
  min-width: 360px;
  color: var(--text-default);
}

:focus:not(:focus-visible) {
  outline: none;
}

/* Hide number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

@media (prefers-reduced-motion: no-preference) {
  :focus {
    outline-offset: 4px;
    outline-color: var(--color-secondary);
  }
}

.black-scrollbar::-webkit-scrollbar,
.react-select__menu-list::-webkit-scrollbar {
  width: 7px;
}
.black-scrollbar::-webkit-scrollbar-thumb,
.react-select__menu-list::-webkit-scrollbar-thumb {
  background: #302d2f;
  border-radius: 100px;
  min-height: 20px;
}
.black-scrollbar::-webkit-scrollbar-track,
.react-select__menu-list::-webkit-scrollbar-track {
  background-color: #c5c5c4;
}

.leaflet-marker-icon {
  cursor: inherit;
}

.react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none;
}

.max-mobile-app-main-height {
  height: calc(100% - 52px);
}

.mobile-hamburger-menu {
  height: calc(100vh - 52px);
}

.logo-loader::before {
  @apply content-[''] absolute logo-loader-conic-gradient w-[348px] h-[348px] -top-[25%] -left-[25%] animate-spin [animation-duration:1s];
}

.logo-loader::after {
  @apply content-['Be'] animate-logo-loader-background-fade bg-green-400 text-white w-[94%] rounded-[20px] h-[94%] absolute top-[3%] left-[3%] flex justify-center items-center tracking-[2px] text-[150px] font-bold;
}

.logo-loader-conic-gradient {
  background: conic-gradient(
    from 180deg at 50% 50%,
    var(--color-green-400) 0deg,
    var(--color-white) 360deg
  );
}

.admin-panel__scrollbar::-webkit-scrollbar {
  width: 1.5rem;
}

.admin-panel__scrollbar::-webkit-scrollbar-thumb {
  background: #e1e1e0;
  border-radius: 5rem;
  border-left: 0.3rem solid white;
  border-right: 0.3rem solid white;
  min-height: 3.5rem;
  background-clip: padding-box;
}

.admin-panel__scrollbar::-webkit-scrollbar-track {
  background: white;
  margin-block: 0.25rem;
}

:nth-child(1 of td.pin-column),
:nth-child(1 of td.unpin-column) {
  box-shadow: inset 8px 0px 10px -10px #888;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.toasterContainer > div > div {
  background-color: transparent;
  box-shadow: none;
}

.tippy-box[data-theme~="admin-tooltip-dark-green-theme"] {
  background-color: var(--color-white);
  border-color: var(--color-ap-light-gray-300);
  border-width: 1px;
  border-radius: 0.5rem;
}
.tippy-box[data-theme~="admin-tooltip-dark-green-theme"][data-placement^="top"]
  > .tippy-arrow::before {
  border-top-color: var(--color-ap-primary-green);
}
.tippy-box[data-theme~="admin-tooltip-dark-green-theme"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: var(--color-ap-primary-green);
}
.tippy-box[data-theme~="admin-tooltip-dark-green-theme"][data-placement^="left"]
  > .tippy-arrow::before {
  border-left-color: var(--color-ap-primary-green);
}
.tippy-box[data-theme~="admin-tooltip-dark-green-theme"][data-placement^="right"]
  > .tippy-arrow::before {
  border-right-color: var(--color-ap-primary-green);
}
.tippy-box[data-theme~="admin-tooltip-dark-green-theme"][data-placement^="top"]
  > .tippy-arrow {
  bottom: -1.5px;
}
.tippy-box[data-theme~="admin-tooltip-dark-green-theme"][data-placement^="bottom"]
  > .tippy-arrow {
  top: -1.5px;
}
.tippy-box[data-theme~="admin-tooltip-dark-green-theme"][data-placement^="left"]
  > .tippy-arrow {
  right: -1.5px;
}
.tippy-box[data-theme~="admin-tooltip-dark-green-theme"][data-placement^="right"]
  > .tippy-arrow {
  left: -1.5px;
}
.tippy-box[data-theme~="admin-tooltip-green-theme"] {
  background-color: var(--color-white);
}
.tippy-box[data-theme~="admin-tooltip-green-theme"][data-placement^="top"]
  > .tippy-arrow::before {
  border-top-color: var(--color-green-400);
}
.tippy-box[data-theme~="admin-tooltip-green-theme"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: var(--color-green-400);
}
.tippy-box[data-theme~="admin-tooltip-green-theme"][data-placement^="left"]
  > .tippy-arrow::before {
  border-left-color: var(--color-green-400);
}
.tippy-box[data-theme~="admin-tooltip-green-theme"][data-placement^="right"]
  > .tippy-arrow::before {
  border-right-color: var(--color-green-400);
}
.tippy-box[data-theme~="admin-tooltip-green-theme"][data-placement^="top"]
  > .tippy-arrow {
  bottom: -1px;
}
.tippy-box[data-theme~="admin-tooltip-green-theme"][data-placement^="bottom"]
  > .tippy-arrow {
  top: -1px;
}
.tippy-box[data-theme~="admin-tooltip-green-theme"][data-placement^="left"]
  > .tippy-arrow {
  right: -1px;
}
.tippy-box[data-theme~="admin-tooltip-green-theme"][data-placement^="right"]
  > .tippy-arrow {
  left: -1px;
}

button:focus,
button:active,
button:focus-visible,
[role="tab"]:focus {
  outline: none;
}

/* gardener-chart styles */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
}
.ball {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  transform: translateX(-50%);
}

.stick {
  width: 4px;
  height: 34px;
  position: absolute;
  top: -10px;
  transform: translateX(-50%);
}
