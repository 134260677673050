.swipper-menu .swiper-slide-visible {
  color: var(--color-gray-400);
}

.swipper-menu .swiper-slide-thumb-active {
  opacity: 1;
  background-color: var(--color-white);
  color: var(--color-green-400);
  height: 3rem;
  border-radius: 1rem;
  margin: auto;
}

.swiper-slide {
  height: auto;
  align-self: stretch;
  border-radius: 2rem;
}

.swiper:not(.swipper-menu) .swiper-slide-active > div {
  padding-bottom: 4rem;
}

section:not(#tools),
body {
  scroll-behavior: smooth;
  overflow-x: clip;
}

.slider-content {
  padding-inline: 3.5rem;
  padding-bottom: 5rem;
  padding-top: 1.5rem;
}

.slider-content__description {
  line-height: 2rem;
  padding-bottom: 6rem;
}

@media (max-width: 1024px) {
  .slider-content {
    padding-inline: 2rem;
    padding-bottom: 1rem;
    padding-top: 0;
  }

  .slider-content__description {
    line-height: 2rem;
    padding-bottom: 1rem;
  }
}
